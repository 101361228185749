import React, { useEffect, useState } from 'react'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha'

const InvisibleCaptcha = ({ smartKey, setSmartExpired, setSmartToken }) => {
	const [isVisible, setVisible] = useState(false)

	const setToken = (token) => {
		setSmartToken(token)
	} 
	
	useEffect(() => {
		setVisible(true)
	}, [])

	return (
		<>
			<InvisibleSmartCaptcha
				sitekey={'ysc1_xicvoORUSoc0iSqVrqENw8eRkVgN8W9gaOg3WOlG0358a6f5'}
				onTokenExpired={() => setSmartExpired()}
				onSuccess={setToken}
				visible={isVisible}
				shieldPosition='bottom-left'
				key={smartKey}
				hideShield={true}
			/>
		</>
	)
}

export default InvisibleCaptcha