import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"

import InvisibleCaptcha from "../SmartCaptcha/index.jsx"

import "./index.scss"

import Success from './success.jsx'

const Form = () => {
	const block = "b-form"
	const [isOpen, setModal] = useState(false)
	const [pdIsChecked, setPdIsChecked] = useState(false)
	const [odIsChecked, setOdIsChecked] = useState(false)
	const [isSmartCreated, setSmartCreated] = useState(false)
	const [isSmartExpired, setSmartExpired] = useState(false)
	const [smartKey, setSmartKey] = useState(0)
	const [smartToken, setSmartToken] = useState('')

	useEffect(() => {
		setSmartKey(prevState => prevState + 1)

		if(isSmartExpired)
			setSmartExpired(false)
	}, [isSmartExpired])

	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const url = `/api/feedback/`

	const onSubmit = () => {
		const form = document.getElementById('form')
		const data = new FormData(form)

		data.append('smartToken', smartToken)

		fetch(url, {
			method: 'POST',
			body: data,
		})
		.then((answer) => { return answer.json() })
		.then(res => {
			if(res.process == 'success') {
				reset()

				setSmartKey(prevState => prevState + 1)
				setOdIsChecked(false)
				setPdIsChecked(false)

				handleClick()
			}
		})
	}

	const handleClick = () => {
		setModal(!isOpen)
		document.getElementsByTagName('body')[0].classList.toggle('hidden')
	}

	const createSmartCaptcha = () => {
		if(!isSmartCreated)
			setSmartCreated(true)
	}

	return (
		<div className={block}>
			<div className={`${block}__form-container`}>
			<div className={`${block}__container`}>
				<div className={`${block}__top`}>
					<span className={`${block}__icon`}></span>
					<div className={`${block}__title`}>
						Связаться с нами
					</div>
				</div>
				{isSmartCreated && <InvisibleCaptcha smartKey={smartKey} setSmartExpired={setSmartExpired} setSmartToken={setSmartToken} />}
				<form onSubmit={handleSubmit(onSubmit)} id="form">
					<div className={`${block}__content`}>
						<div className={`${block}__inputs`}>
							<input
								placeholder="Имя"
								className={`${block}__input ${errors.name != undefined ? '_invalid' : ''}`}
								{...register("name", { required: true, onChange: () => createSmartCaptcha() })}
							/>
							<input
								placeholder="Email"
								className={`${block}__input ${errors.email != undefined ? '_invalid' : ''}`}
								{...register("email", {
									required: true,
									pattern:
										/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
								})}
							/>
							<textarea
								placeholder="Что вас интересует?"
								className={`${block}__textarea ${errors.message != undefined ? '_invalid' : ''}`}
								{...register("message", {required: true})}
							/>
						</div>
						<div className={`${block}__button`}>
						<div className={`${block}__personal`}>
							<div className={`${block}__checkbox`}>
								<label>
									<span className={`${block}__fake-checkbox
										${errors.pd != undefined ? '_invalid' : ''} 
										${pdIsChecked && '_checked'}`}
									>
									</span>
									<input type="checkbox" className={`${errors.pd != undefined ? '_invalid' : ''}`}
										{...register("pd", {
											required: true,
											onChange: () => setPdIsChecked(!pdIsChecked),
										})}
										checked={pdIsChecked ? true : false}
									/>
								</label>
								<span className={`${block}__checkbox-text`}>
									Я даю согласие на <a href="/agreement/">обработку персональных данных</a>
								</span>
							</div>
							<div className={`${block}__checkbox`}>
								<label>
									<span className={`${block}__fake-checkbox 
										${errors.od != undefined ? '_invalid' : ''}
										${odIsChecked ? '_checked' : ''}`}
									>
									</span>
									<input type="checkbox"
										{...register("od", {
											required: true,
											onChange: () => setOdIsChecked(!odIsChecked),
										})}
										checked={odIsChecked ? true : false}
									/>
								</label>
								<span className={`${block}__checkbox-text`}>
									Я ознакомлен с <a href="/privacy-policy/">политикой обработки данных</a>
								</span>
							</div>
						</div>
						<input className={`${block}__submit`} type="submit"  value="Оставить заявку"/>
					</div>
					</div>
				</form>
				{isOpen && <Success handleClick={handleClick} />}
			</div>
			</div>
		</div>
	)
}

export default Form
